
.application-content {
    padding: 20px 0;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .app-content {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .select-wrapper {
        padding: 0 20px;
        .select-btn {
            display: flex;
            justify-content: flex-end;
            .checkbox-all {
                margin-right: 20px;
            }
        }
    }
    .app-list-ul {
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        li.app-list-li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 16px 20px 0;
            cursor: pointer;
            border-radius: 10px;
            &:hover {
                transform: scale(1.2);
                transition: 1s;
            }
            .app-cover {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
                height: 70px;
                .checkbox {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    .checkbox-group {
                        .checkbox-label {
                           ::v-deep .el-checkbox__label {
                                display: none;
                            }
                        }
                    }
                    .edit-app-btn {
                        position: absolute;
                        bottom: 0;
                        background-color: #DCDFE6;
                        border-radius: 50%;
                        height: 16px;
                        width: 16px;
                        text-align: center;
                        line-height: 16px;
                        i {
                            font-size: 12px;
                            color: #979aa0;
                        }
                        &:hover {
                            background-color: #409EFF;
                            i {
                                font-size: 12px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            img.app-icon {
                max-width: 100%;
                max-height: 100%;
                border-radius: 5px;
            }
            span.app-name {
                color: #333;
                font-size: 12px;
                margin-top: 10px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 216px;
                text-align: center;
            }
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
}
.app-pages {
    padding: 0 20px;
}
.upload-cover {
    position: relative;
    background-color: #DCECFE;
    border-color: #DCECFE;
    padding: 0;
    width: 80px;
    height: 80px;
    color: #409EFF;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    border-radius: 10px;
    i {
        width: 16px;
        height: 16px;
        margin: 5px;
    }
}
.cover-input {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0;
    opacity: 0;
    cursor: pointer;
}
.up-img-show {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0;
    left: 100px;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.app-detail-dialog {
    ::v-deep .el-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #e6e7ea;
        }
        .title {
            text-align: center;
            margin: 0;
        }
        .content {
            display: flex;
            flex-direction: column;
            .h2 {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin: 10px 0;
            }
            .soft-introduction {
                text-align: center;
            }
            .soft-content {
                padding: 10px 50px;
                min-height: 200px;
            }
        }
        ::v-deep .el-dialog__footer {
            border-top: 1px solid #e6e7ea;
            padding: 20px;
            a {
                padding: 7px 20px;
                font-size: 14px;
                border-radius: 4px;
                margin-right: 20px;
            }
            a.home {
                border: 1px solid #DCDFE6;
                color: #606266;
                &:hover {
                    color: #409EFF;
                    border-color: #c6e2ff;
                    background-color: #ecf5ff;
                }
            }
            a.shop {
                color: #FFF;
                background-color: #409EFF;
                border-color: #409EFF;
                &:hover {
                    background: #66b1ff;
                    border-color: #66b1ff;
                    color: #FFF;
                }
            }
        }
    }
}
